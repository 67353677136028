import "./removeUserPrize.css"
import React, { useEffect, useRef, useState } from "react"
import QrScanner from "react-qr-scanner";
import { Modal } from "react-bootstrap";
import jsQR from "jsqr";
import { performHttpRequest } from "../api/http/generic";
import PATH from "../configuration/api/http/path";
 export default function RemoveUserPrize(){
     

    const [qrResult, setQrResult] = useState(null);
    const [devices, setDevices] = useState([]);
    const [selectedDevice, setSelectedDevice] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
     const videoRef = useRef(null);
    const canvasRef = useRef(null);
    const lastScanTime = useRef(0);
    
    const SCAN_DELAY = 500;


    const [userPrize, setUserPrize] = useState(null);
    const [posUser, setPosUser] = useState(null);

    const [prizesToAdd, setPrizesToAdd] = useState(0);

    useEffect(() => {
 

        let setvideo = true;
          navigator.mediaDevices.getUserMedia({ video: true }).then(()=>{
                navigator.mediaDevices.enumerateDevices().then(devices=>{
                let videoCameras = devices.filter((device) => device.kind === "videoinput");
                 setDevices(videoCameras);
                
                 let finalDev = {l:200,d:null};
                 for(let index in videoCameras)
                 {
                    let vd = videoCameras[index];
                    if( (vd.label.toLowerCase().includes("back") || vd.label.toLowerCase().includes("spate") || vd.label.toLowerCase().includes("rear") || vd.label.toLowerCase().includes("environment")|| vd.label.toLowerCase().includes("mediu")) && vd.label.length<finalDev.l)
                    {
                        finalDev.l = vd.label.length;
                        finalDev.d = vd;
                    }
                 }
                 if(finalDev.d === null)
                 {
                    if(videoCameras.length>0)
                    {
                        finalDev.d = videoCameras[0];
                    }
                 }
                    setTimeout(()=>{if(setvideo){setSelectedDevice(finalDev.d);}}, 500);
                });});

                return ()=>{
                    setvideo=false;
                }
       
         
    }, []);

    

    useEffect(()=>{
        if(qrResult)
        {
            fetchUserPrize(qrResult);
        }
    },[qrResult]);
    let fetchUserPrize = (qr)=>{

      try{
        let qrObj = JSON.parse(qr);
        if(qrObj.phone)
        {

          performHttpRequest("POST", PATH.getUserByPhone, null, qr, (r,c,e,j)=>{
            if(c>=200 && c<=206 && j)
            {
                setPosUser(r);
            }
            else{
                setPosUser(null);
            }
        });

          //TODo
          //getUerByPhone
        }
        if(qrObj.id && qrObj.qr){
        performHttpRequest("POST", PATH.getUserPrizeByQr, null, qr, (r,c,e,j)=>{
            if(c>=200 && c<=206 && j)
            {
                setUserPrize(r);
            }
            else{
                setUserPrize(null);
            }
        });
      }
    }catch(e){}
  }
    let validateUserPrize = (qr)=>{
        performHttpRequest("POST", PATH.validateUserPrize, null, JSON.stringify(qr), (r,c,e,j)=>{
            if(c>=200 && c<=206 && j)
            {
                setUserPrize(null);
            }
            else{
                setUserPrize(null);
            }
        });
    }
    let addUserAbailablePrizes = (phone, nr)=>{
      performHttpRequest("POST", PATH.addUserAbailablePrizes, null, JSON.stringify({phone:phone, availablePrizes:nr}), (r,c,e,j)=>{
          if(c>=200 && c<=206 && j)
          {
              setPosUser(null);
          }
          else{
              setPosUser(null);
          }
      });
  }

    useEffect(() => {
        if (!selectedDevice) return;
      
        const constraints = { video: { deviceId: selectedDevice.deviceId } };
        navigator.mediaDevices.getUserMedia(constraints).then((stream) => {
          const video = videoRef.current;
          if (video) {
            video.srcObject = stream;
            video.setAttribute("playsinline", true); // Required for mobile devices
            video.play();
      
            // Start scanning after the video is ready
            video.onloadeddata = () => {
              requestAnimationFrame(scan);
            };
               }
        });
     
  
      }, [selectedDevice]);
      
      const scan = () => {


        const now = Date.now();
        if (now - lastScanTime.current < SCAN_DELAY) {
            requestAnimationFrame(scan); // Skip decoding if within delay
            return;
        }
        lastScanTime.current = now;
        const video = videoRef.current;
        const canvas = canvasRef.current;
        if (!video || !canvas) return;
      
        const context = canvas.getContext("2d");
      
        // Check video dimensions
        if (video.videoWidth > 0 && video.videoHeight > 0) {
          canvas.width = video.videoWidth;
          canvas.height = video.videoHeight;
      
          // Draw the video frame onto the canvas
          context.drawImage(video, 0, 0, canvas.width, canvas.height);
      
          // Extract image data for QR decoding
          const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
          const code = jsQR(imageData.data, imageData.width, imageData.height);
      
          if (code) {
            setQrResult(code.data); // Display QR result
            requestAnimationFrame(scan);
          } else {
            // Continue scanning
            requestAnimationFrame(scan);
          }
        } else {
          // Retry scanning until video dimensions are available
          requestAnimationFrame(scan);
        }
      };
      

/*
    const handleScan = (data) => {
        
      if (data && data.text) {

        alert(data.text);
      }
    };
  
    const handleError = (error) => {
      console.error(error);
    };
  /*     /*
        <QrScanner className="qrScanner"

          // 'environment' prefers back camera

          delay={500}
          onScan={handleScan}
          onError={handleError}
           chooseDeviceId =  {(pa)=>{console(JSON.stringify(pa));return selectedDevice;}}
           
          
        />*/
    return (
      <div className="qrPrizeMain">
    
    <video className = "qrScanner" ref={videoRef}></video>
      <canvas ref={canvasRef} style={{ display: "none" }}></canvas>

      <p style={{color:"white", textAlign:"center"}}>QR Code Result: {qrResult || "No QR code detected"}</p>


        <div className = "changeCamera" onClick= {()=>{setModalOpen(true)}}> Schimba camera</div>
 
             <Modal size="lg"  centered show = {modalOpen} backdrop="true" onHide={()=>setModalOpen(false)}>
                <Modal.Header   closeButton>
                Alege o camera:
                </Modal.Header>
                <Modal.Body >
                 {devices  && devices.map(d=>{
                    return (<div className="inputDeviceVariant" onClick = {()=>{setSelectedDevice(d); setModalOpen(false)}}>{d.label}</div>);
                 })}

                </Modal.Body>

            </Modal>


            <Modal size="lg"  centered show = {userPrize} backdrop="true" onHide={()=>{setUserPrize(null); setQrResult(null);}}>
                <Modal.Header   closeButton>
                    Validati premiul:
                </Modal.Header>
                <Modal.Body >
                  <div className="prizeValidationModalBody">
                    <div style={{paddingBottom:"2rem"}}>{(userPrize&&userPrize.prize?(""+userPrize.prize.title+"  -  "+userPrize.prize.fullTitle):"")}</div>
                    <button  className ="prizeValidationButton" onClick={()=>{validateUserPrize({id:userPrize.id, qr:userPrize.qr}); setQrResult(null);}}>Validare</button>
                  </div>
                </Modal.Body>

            </Modal>
            <Modal size="lg"  centered show = {posUser} backdrop="true" onHide={()=>{setPosUser(null); setPrizesToAdd(0); setQrResult(null);}}>
                <Modal.Header   closeButton>
                    Adaugati pozitii libere in portofel:
                </Modal.Header>
                <Modal.Body >
                  <div className="prizeValidationModalBody">
                  <div  style={{paddingBottom:"0.5rem"}}>{posUser?posUser.phone:""}</div>


                  <button  className ="prizeValidationButton" onClick={()=>{  setPrizesToAdd(prizesToAdd+1);}}>&nbsp;+&nbsp;</button>
                  
                    <div style={{padding:"0.5rem",  fontWeight:"bold"}} >{prizesToAdd}</div>
                    <button  style={{marginBottom:"3rem"}} className ="prizeValidationButton" onClick={()=>{  setPrizesToAdd(prizesToAdd-1);}}>&nbsp;-&nbsp;</button>

                    <button  className ="prizeValidationButton" onClick={()=>{addUserAbailablePrizes(posUser.phone, prizesToAdd); setPrizesToAdd(0); setQrResult(null);}}>Adaugare</button>
                  </div>
                </Modal.Body>

            </Modal>
      </div>
    );
 


}