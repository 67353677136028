import "./boxinput.css"
import React from "react"

export default function BoxInput({length, value, onInput, inputType, disabled}){

    
    let computeIndex = ()=>{
        if(value)
            {
                if(typeof(value) === typeof("ana") && value.length <length)
                    {
                        return value.length;
                    }
                    if(typeof(value) === typeof("ana") && value.length === length)
                    {
                        return value.length-1;
                    }
                
            }
            return 0;
    }
    let getValueAt = (index)=>{
        if(typeof(value) === typeof("ana") && value.length >index)
            {
                return value.charAt(index);
            }
        return "";

    }

    let handleUserInput = (index, userChar,event )=>{

        console.log(event);
        if(event.key === "Backspace" && !userChar && value && index>=value.length && index>0)
            {
                onInput(value.substring(0,index-1));
                return;
            }
        let finalValue = value;
        if(!value)
            {
                onInput(userChar);
                return;
            }
        if(index <value.length)
            {
                finalValue = value.substring(0, index) + userChar + value.substring(index + 1)
            }
            if(index=== value.length)
                {
                    finalValue = value+userChar;
                }
        onInput(finalValue);
    }

    
    let generateContent = ()=>{
        let content = [];
        let activeIndex = computeIndex();

        let activeRef = null;
        for(let i = 0 ; i< length; i++)
        {
            content.push(
              <input 
              style = {{width:"min(calc((100% / "+length+") - 0.2rem )  , 3rem )"}} 
              disabled={disabled} type={inputType?inputType:"text"} 
              pattern={((inputType==="number")?"[0-9]*":undefined)}  
              inpputMode={((inputType==="number")?"numeric":undefined)}  
              autoCapitalize={"none"} maxlength="1" class="digit" 
              /*autoFocus={activeIndex===i} */
              ref={(r)=>{if(r && activeIndex === i){activeRef = r; r.focus();}}} 
              value={getValueAt(i)} onInput={(e)=>handleUserInput(i, e.target.value, e)} 
              onKeyDown={e=>handleUserInput(i,e.target.value, e)} 
              onFocus = {()=>{if(i>activeIndex && activeRef){activeRef.focus()}}}
              
              />

            );
        }
        return content;
    }

    return (
        <div className="boxInputMain">
            <div class="box-input-container">
                {generateContent()}
            </div>

        </div>
    );

}
