import logo from './logo.svg';
import './App.css';
 import Authenticate from './login/authenticate.js';
import Parent from './contexttest/parent.js';
import { BrowserRouter, Route, Routes } from 'react-router';
import AdminPrize from './prize/prize';
import LuckyWheel from './lucky/lucky';
import LuckyPage from './lucky/luckyPage';
import WonPrizePage from './wonPrize/wonPrizePage';
import RemoveUserPrize from './removeUserPrize/removeUserPrize';
import { useEffect, useState } from 'react';
import { performHttpRequest } from './api/http/generic';
import PATH from './configuration/api/http/path';
import Home from './home/home';
import Topbar from './topbar';
import PROXY from './configuration/proxy/proxy';
import OpenInBrowser from './openinbrowser/openinbrowser.js';
 
function App() {
  let [user, setUser] = useState(null);

  let [firstFecth, setFirstFetch] = useState(false);
  useEffect(()=>{
    fetchUser();
  },[]);
  let fetchUser = ()=>{
    performHttpRequest("GET", PATH.authenticatedUser, null, null,  (r,c,e,j)=>{

      if(c>=200 && c<=206 && j)
      {
        setUser(r);
      }
      else{
        setUser(null);
      }
      setFirstFetch(true);
    
    }, true);
  }
  return (
    <div className="App">
      <div className='appMain'>
        <div className='appBackground'></div>
     

      <div className='appContent'>
      {firstFecth && <BrowserRouter basename={PROXY.frontend}>
      <Topbar authenticatedUser = {user}/>
      <div className="whiteSpace"/>

          <Routes>
            <Route  path='/' element={<Home authenticatedUser={user} fetchUser = {fetchUser}/>}/>
            <Route  path='/login' element={ <Authenticate authenticatedUser = {user} fetchUser = {fetchUser}/>}/>
            <Route  path='/openInBrowser' element={ <OpenInBrowser/>}/>

            {user && <Route path='/prize' element={<AdminPrize/>}/>}
            {user && <Route path='/wheel' element={<LuckyPage/>}/>}
             {user && <Route path="/wallet" element={<WonPrizePage authenticatedUser={user}/>}/>}

             {user && <Route path="/pos/prize" element={<RemoveUserPrize/>}/>}
             <Route  path='*' element={ <Authenticate authenticatedUser = {user} fetchUser = {fetchUser}/>}/>


          </Routes>
      </BrowserRouter>}
      </div>
      </div>
    </div>
  );
}

export default App;
