import "./prize.css"
import React, { useEffect, useState } from "react"
import GenericTable from "../table/table";
import { generateTableBody, generateTableHeader } from "../tableContentGenerator/genericTableGenerators";
import PATH from "../configuration/api/http/path";
import { performHttpRequest } from "../api/http/generic";
import PrizeCreate from "./create/itemCreate";
import ItemUpdate from "./update/itemCreate";

export default function AdminPrize({}){

    let prizeProperties = ["title", "fullTitle", "probability", "active"];
    let displayPrizeProperties = ["Titlu", "Titlu complet", "Probabilitate castig", "Activ"];


    let [prizes, setPrizes] = useState([]);

    let [updatePrize, setUpdatePrize] = useState(null);
    let [updateActivated, setUpdateActivated] = useState(false);

    useEffect(()=>{
        fetchItems();
    },[]);
    const fetchItems = ()=>{
        performHttpRequest("GET", PATH.getPrizes, null, null, (r, c, e, iJ)=>{
            if(c>=200 && c<=206 && iJ)
            {
                setPrizes(r);
            }
        });
    }

    return (
        <div className="adminPrizeMain">
            <PrizeCreate itemCreationHandler={()=>{fetchItems()}}/>
            <div className="tableContainer">
                <GenericTable tableHeader={generateTableHeader(displayPrizeProperties)} tableData={generateTableBody(prizes, prizeProperties)} handleSelectedRow={(p)=>{setUpdatePrize(p); setUpdateActivated(true)}}/>
            </div>
            {updateActivated && updatePrize!=null && 
                <ItemUpdate
                    item={updatePrize} 
                    itemCreationHandler={()=>{setUpdatePrize(null); setUpdateActivated(false); fetchItems();}}   
                    hideHandler={()=>{setUpdatePrize(null); setUpdateActivated(false);}}/>
            }
        </div>
    );

}