const PATH  ={
    authenticatedUser:"/user",
    logout:"/logout",
    invocationRoot : "/reflexion/raw/invocation",
    introspectionRoot : "/reflexion/raw/introspection",
    dashboardMap: "/dashboard/1",

    getPrizes:"/prize",
    createPrize:"/prize",
    updatePrize:"/prize",
    deletePrize:"/prize",
    getWheel:"/prize/wheel",
    addPrize:"/prize/won",
    getWonPrizes:"/prize/won",
    getUserPrizeByQr:"/prize/qr",
    validateUserPrize:"/prize/validate",

    getUserByPhone:"/user/phone",
    addUserAbailablePrizes:"/user/addAvailablePrizes"
}

export default PATH;

