import "./authenticate.css";
 import React, { useEffect, useState } from "react";
 import {performHttpRequest} from "../api/http/generic.js";
import BoxInput from "../boxinput/boxinput.js";
import { useNavigate } from "react-router";


export default function Authenticate( {authenticatedUser, fetchUser}){


    let [phone, setPhone] = useState("07");
    let [code, setCode] = useState("");
    let [codeActive, setCodeActive] = useState(false);
  
    let [codeRequestLock, setCodeRequestLock] = useState (false);
    let [codeRequestLockTimer, setCodeRequestTimer] = useState(0);

    const redirect  = useNavigate();



    let [error, setError] = useState(null);

    useEffect(()=>{
        if(authenticatedUser)
        {
             redirect("/");
        }
    },[authenticatedUser]);
    useEffect(()=>{
        if(error)
        {
            let tm = setTimeout(()=>setError(null), 10000);
            return ()=>{
                clearTimeout(tm);
            }
        }
         
    },[error]);
    useEffect(()=>{
        let newValue = codeRequestLockTimer -1;
        if(newValue>=0)
        {
            let tI = setTimeout(()=>setCodeRequestTimer(newValue), 1000);
            return ()=>{
                clearTimeout(tI);
            }

        }
        else{
            setCodeRequestLock(false);
        }
        
    },[codeRequestLockTimer]);
    let continuousDecremetCodeRequestLockTimer = (init)=>{
        
        setCodeRequestTimer((old)=>{
             let newValue = init || (old-1);
            console.log("continuousDecremetCodeRequestLockTimer", old, init, newValue);

            if(newValue>0)
            {
                 
                setTimeout(continuousDecremetCodeRequestLockTimer, 1000);
                return newValue;

               
            }
            else{
                setCodeRequestLock(false);
                return old;
            }
           

        });
    }
    let displayRequestCode =()=>{
        //if(!codeActive){
          //  return true;
       // }
       // else{
            return !codeRequestLock;
       // }
    }

    /*
     useEffect(()=>{
         
        let inputs = [];
        inputs.forEach((input, index) => {
            input.addEventListener("input", (event) => {
                const value = event.target.value;
                if (value.length === 1 && index < inputs.length - 1) {
                    inputs[index + 1].focus();
                }
            });
        
            input.addEventListener("keydown", (event) => {
                if (event.key === "Backspace" && !input.value && index > 0) {
                    inputs[index - 1].focus();
                }
            });
        });
        
    },[]);
*/
    let requestCode = ()=>{
        performHttpRequest("POST", "/authenticate",null,JSON.stringify({phone:phone}), (r,c,e,j)=>{

            if(c==200)
                {
                    setCode("");
                    setCodeActive(true);
                    setCodeRequestLock(true);
                    setCodeRequestTimer(90);
                    
                    
                }
                
                if(c>300 && c<600)
                {
                    console.log(e);
                    setError(e);
                }
        });
    }
    let validateCode = ()=>{
        performHttpRequest("POST", "/authenticate",null,JSON.stringify({phone:phone, code:code}), (r,c,e,j)=>{

            if(c==200)
                {
                    //alert("authenticated");
                    fetchUser();
                }
            if(c>300 && c<600)
            {
                setError(e);
            }
        });
    }
    function extractRomanianPhoneNumber(input) {
        // Regular expression to match a Romanian phone number starting with "07"
        const phoneRegex = /07\d{0,8}/;
        const match = input.match(phoneRegex);
        
        if (match) {
          return match[0]; // Return the matched portion
        } else {
          return "07"; // No valid phone number found
        }
      }
    return (
        <div className="authenticateMain">
            
            <div className="contentContainer">
                {error && <div className="errorContainer">{error}</div>}

                <div className="spam"/>
                <div className="infoText"> Introduceti numarul de telefon</div>
                <div className="inputContainer">
                    
                
                    <BoxInput  disabled = {codeActive} inputType={"number"} value = {phone} length={10} onInput={(v)=>{setPhone(extractRomanianPhoneNumber(v));console.log(v)}}/>
                    
                    <div className="spam"/>
                    <div className="actionContainer">

                        {(codeActive) &&     <div className="button" onClick={()=>{  setCodeActive(false)}}>Modifica numarul</div>}
                   
                        <div className={"button"+(displayRequestCode()?"": " disabled")}   disabled={!displayRequestCode()} onClick={()=>{if(displayRequestCode()){ requestCode()}}}>{"Solicita cod" + (codeRequestLockTimer?" nou in "+codeRequestLockTimer+"s":"")}</div>
                   
                    </div>

                 

                </div>
                <div className="spam"/>
                
                {codeActive && <div className="infoText"> Introduceti codul primit prin SMS</div>}

                {codeActive && <div className="inputContainer">
 
                    <BoxInput  value = {code} length={4} onInput={(v)=>{setCode((v));console.log(v)}}/>    
                    <div className="spam"/>    
                    <div className="button" onClick={()=>validateCode()}>Valideaza cod</div>
                </div>}
            </div>

            
       

        </div>
    );
}
