import React from "react";
import "./table.css";
export default function GenericTable({tableHeader, tableData, handleSelectedRow}){



    const generateHeader = function(){

        let response = [];

        for(let headerIndex in tableHeader)
        {
            let header = tableHeader[headerIndex];
            response.push(
               <th>{header.displayValue}</th>
            );
        }
        return response;

    }

    const generateTableBody = function(){

        let response = [];

        for(let tableRowIndex in tableData)
        {
            let tableRow = tableData[tableRowIndex];
            let generatedRow = [];
            for(let tableColumnIndex in tableRow.data){
                let tableColumn = tableRow.data[tableColumnIndex];

                 
                generatedRow.push(
                <td
                        onClick={()=>(handleSelectedRow && !tableColumn.ignoreSelection )?handleSelectedRow(tableRow.meta):""}
                >{tableColumn.displayValue}</td>
                    );
            }
            response.push(
                <tr >
                    {generatedRow}
                </tr>
            );
        }
        return response;

    }

    return (

        <div className="genericTableMain">

            <table className="genericTable">
                <thead>
                    <tr>
                        {generateHeader()}
                    </tr>
                </thead>
                <tbody>
                    {generateTableBody()}
                </tbody>

            </table>

        </div>
    );
}