import "./home.css"
import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router";
import { performHttpRequest } from "../api/http/generic";
import PATH from "../configuration/api/http/path";




export default function Home({authenticatedUser, fetchUser}){


    const redirect = useNavigate();
    let [logoutActive, setLogoutActive] = useState(false);
    useEffect(()=>{
        if(authenticatedUser)
        {
        }
        else{
             redirect("/login");

        }
    },[authenticatedUser]);

    let logOut = ()=>{
        performHttpRequest("GET", PATH.logout, null, null ,(r,c,e,j)=>{
            fetchUser();
        });
    }

    return(<div className="homeMain">
        <div className="buttonListWrapper">
            {authenticatedUser &&  <div className="bW"><button onClick={()=>redirect("/wallet")}>Portofelul meu</button></div>}
            {authenticatedUser &&  <div className="bW"><button onClick={()=>redirect("/wheel")}>Invarte roata</button></div>}

            {authenticatedUser && (authenticatedUser.role === "ADMIN" || authenticatedUser.role === "POS") && <div className="bW"><button onClick={()=>redirect("/pos/prize")}>Punct de vanzare</button></div>}
            {authenticatedUser && authenticatedUser.role === "ADMIN" && <div className="bW"><button onClick={()=>redirect("/prize")}>Administrare premii</button></div>}
           
           <div className="whiteLine"></div>
            <div className="bW"><button onClick={()=>setLogoutActive(true)}>Deblocati deconectarea</button></div>
            {logoutActive && <div className="bW"><button onClick={()=>setLogoutActive(false)}>Renuntare deconectare </button></div>}

            {logoutActive && <div className="bW"><button onClick={()=>logOut()}>Confirmare deconectare </button></div>}

        </div>
        </div>);
}