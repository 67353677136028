import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router";


export default function Topbar({authenticatedUser}){


    const redirect = useNavigate();
 
 

    return(
      <div   className={"topbarMain"} >
                                                         
                                            
                                            <div className="topbarContent">
                                                    <div className="logo" onClick={()=>redirect("/")}>
                                                        MAXI
                                                    </div>
                                                    <div className="decorationWrapper">
                                                    <div className="decoration"></div>
                                                    </div>
                                                    <div className="profile">
                                                    {authenticatedUser?authenticatedUser.phone:""}
                                                    </div>
                                                    </div>

        </div>);
}