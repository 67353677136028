import "./wonPrizePage.css";
import React, { useEffect, useState } from "react";
import { performHttpRequest } from "../api/http/generic";
import PATH from "../configuration/api/http/path";
import QRCode from "react-qr-code";
import { useNavigate } from "react-router";



export default function WonPrizePage({}){



    let [userPrizes, setUserPrizes] = useState([]);
    let [activeQr, setActiveQr] = useState(null);
    let [activeInfo, setActiveInfo] = useState(false);
    let [authenticatedUser, setAU] = useState();
    const redirect = useNavigate();
    useEffect(()=>{

        fetchAuthUser();
        fetchWonPrizes();

        let t  = setInterval(()=>{fetchWonPrizes(); fetchAuthUser()}, 5000);
        return ()=>{clearInterval(t);}
    },[]);
    useEffect(()=>{

        if(activeInfo===true)
        {
            let t = setTimeout(()=>setActiveInfo(false), 3000);
            return ()=>{
                clearTimeout(t);
            }
        }
    },[activeInfo, activeQr]);

    let fetchAuthUser = ()=>{

        performHttpRequest("GET", PATH.authenticatedUser, null, null, (r,c,e,j)=>{
            if(c>=200 && c<=206 && j)
            {
                setAU(r);

            }
        })

    };
    let fetchWonPrizes = ()=>{

        performHttpRequest("GET", PATH.getWonPrizes, null, null, (r,c,e,j)=>{
            if(c>=200 && c<=206 && j)
            {
                setUserPrizes(r);

            }
        })

    };

    let generateUserTab  = ()=>{
        let result = [];
        result.push(
            <div className="prizeWrapper">
              
            </div>
        );
            result.push(
                <div className="prizeWrapper">
                      <div className="prizeBorderWrapper profile">
                    <div className="prize profile whl" onClick = {()=>redirect("/wheel")}>
                        
                        <div className="prizeText">
                            <div  className="title">
                                Invarte roata
                            </div>
                            
                        </div>
                        
                    </div>
                </div>
                    <div className="prizeBorderWrapper profile">
                        <div className="prize profile">
                            <div>
                                {authenticatedUser && <QRCode size="3rem" value={JSON.stringify({phone:authenticatedUser.phone})} onClick  = {()=>{setActiveQr({phone:authenticatedUser.phone}); setActiveInfo(true);}}/>}
                            </div>
                            <div className="prizeText">
                                <div className="title">
                                    {authenticatedUser?authenticatedUser.phone:""}
                                </div>
                                <div className="subtitle">
                                    {"Mai puteti salva "+(authenticatedUser?authenticatedUser.availablePrizes:"0") + ((authenticatedUser&&authenticatedUser.availablePrizes==1)?" premiu":" premii")}
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    
                </div>
            );
        
        return result;
    }

    let generatePrizeList = ()=>{
        let result = [];
        for(let  index in userPrizes){

            result.push(
                <div className="prizeWrapper">
                    <div className="prizeBorderWrapper">
                        <div className="prize">
                            <div  className="prizeText">
                                <div className="title">
                                    {userPrizes[index].prize.fullTitle}
                                </div>
                                <div className="subtitle">
                                    {userPrizes[index].prize.description}
                                </div>
                            </div>
                            <div className="qrWrapper">
                                <QRCode size="3rem" value={JSON.stringify({id:userPrizes[index].id, qr:userPrizes[index].qr})} onClick  = {()=>{setActiveQr({id:userPrizes[index].id, qr:userPrizes[index].qr}); setActiveInfo(true);}}/>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        return result;
    }
    return (
        <div className="userPrizeMain">
                
            {activeQr && <div onClick={()=>{setActiveQr(null)}}className="userPrizeQrModal">
                {activeInfo && <div className="info">Apasati oriunde pentru a inchide</div>}
                <QRCode value={JSON.stringify(activeQr)} size="min(85vw, 85vh)"/>

            </div>}
            <div className="backgroundContainer_expired"></div>
            <div className="contentContainer">
                <div className="prizeListWrapper"> 
                    <div className="prizeList">
                        {generateUserTab()}
                        <div className="savedPrizesTextWrapper">

                            <div className="savedPrizesText"> 
                            {userPrizes && userPrizes.length?"Premii salvate:":"Nu aveti premii salvate"}
                            </div>
                            </div>
                        {generatePrizeList()}
                     </div>
                     <div className="prizesInfo">Va asteptam cu premiile castigate in magazinele noastre MAXI din Turda, Centrul Istoric, Strada Piata Republicii, numerele 16, 19 si 23. De asemena in magazine puteti obtine mai multe pozitii libere in portofel pentru a salva premiile castigate.</div>

                    </div>
 
            </div>
        </div>
    );

}