import React, { useState } from "react";
import "./itemCreate.css";
import { Modal } from "react-bootstrap";
 
import PATH from "../../configuration/api/http/path";
import { performHttpRequest } from "../../api/http/generic";
export default function ItemUpdate({item, itemCreationHandler, hideHandler})
{
    

    let [title, setTitle] = useState(item.title?item.title:"");
    let [fullTitle, setFullTitle] = useState(item.fullTitle?item.fullTitle:"");
    let [description, setDescription] = useState(item.description?item.description:"");
    let [active, setActive] = useState(item.active);
    let [probability, setProbability] = useState(item.probability?item.probability:1);

    const createNewItem = ()=>{

        let body = {
            id: item.id,
            title: title,
            fullTitle:fullTitle,
            description: description,
            active:active,
            probability:probability
        }


        performHttpRequest("PUT", PATH.updatePrize, null, JSON.stringify(body), (r,c,e,iJ)=>{
            if(c>=200 && c<=206 && iJ)
            {
                alert("Prize updated : "+ JSON.stringify(iJ));
            }
            else{
                alert("Error");

            }
            itemCreationHandler();
         })
         setTitle("");
         setFullTitle("");
         setDescription("");
         setActive("");
         setProbability("");
    }
    const deleteItem = ()=>{

      

        performHttpRequest("DELETE", PATH.deletePrize, null, JSON.stringify({id:item.id}) , (r,c,e,j)=>{
            if(c>=200 && c<=206)
            {
                alert("Item deleted");
            }
            else{
                alert("Error");

            }
            itemCreationHandler();
         })
         setTitle("");
         setFullTitle("");
         setDescription("");
         setActive("");
         setProbability("");
    }

    return(

        <div className="itemCreateMain">

      
           
         
            <div className="">
                
                


                <Modal size="lg" show = {true} backdrop="static" onHide={()=>hideHandler()}>
                <Modal.Header closeButton>
                    Actualizeaza premiul
                </Modal.Header>
                <Modal.Body>
                    <div className="itemCreateModal">
                        <div>Titlu:</div>
                        <input type="text" value={title} onInput={(event)=>setTitle(event.target.value)}/>
                        <div>Titlu complet:</div>
                        <input type="text" value={fullTitle} onInput={(event)=>setFullTitle(event.target.value)}/>
                        <div>Descriere:</div>
                        <textarea type="text" value={description} onInput={(event)=>setDescription(event.target.value)}/>
                        <div>Probabilitate de castig:</div>
                        <input type="number" min="0" max="100" value={probability} onInput={(event)=>setProbability(event.target.value)}/>
                        <div>Premiu activ:</div>
                        <input type="checkbox"  checked={active} onInput={(event)=>setActive((active?false:true))}/>
                        <div className="updateItemSubmitButtonContainer"> 
                            <button className="deleteItemSubmitButton" onClick={()=>deleteItem()}>Delete</button>
                            <button className="createItemSubmitButton" onClick={()=>createNewItem()}>Update</button>
                        </div>
                    </div>

                </Modal.Body>

            </Modal>
            </div>
         
        </div>
    );

}