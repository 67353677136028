const generateTableHeader =(propertyList)=>{
    let tableHeader =[];

    for(let index in propertyList)
    {
        let property = propertyList[index];
        tableHeader.push({displayValue: property});
    }
    return tableHeader;
    
}
const generateTableBody = (items, itemProperties )=>{
    let tableData = [];
    for(let itemIndex in items)
    {
        let item = items[itemIndex];
        let itemTableRow ={};
        itemTableRow.meta = item;
        itemTableRow.data = [];
        for(let pIndex in itemProperties)
        {
            let propertyKey = itemProperties[pIndex];
            itemTableRow.data.push({displayValue:new String(item[propertyKey])});

        }
        tableData.push(itemTableRow);
    }
    return tableData;
};

export {generateTableBody, generateTableHeader};