import React, { useState } from "react";
import "./itemCreate.css";
import { Modal } from "react-bootstrap";

import PATH from "../../configuration/api/http/path";
import { performHttpRequest } from "../../api/http/generic";
export default function PrizeCreate({itemCreationHandler})
{
    let [activated, setActivated] = useState(false);

    let [title, setTitle] = useState("");
    let [fullTitle, setFullTitle] = useState("");
    let [description, setDescription] = useState("");
    let [active, setActive] = useState(false);
    let [probability, setProbability] = useState(1);


    const createNewPrize = ()=>{

        let body = {
            title: title,
            fullTitle:fullTitle,
            description: description,
            active:active,
            probability:probability
        }

        performHttpRequest("POST", PATH.createPrize, null, JSON.stringify(body), (r,c,e,iJ)=>{
            if(c>=200 && c<=206 && iJ)
            {
                alert("Prize created : "+ (iJ? JSON.stringify(iJ):""));
            }
            else{
                alert("Error");

            }
            if(itemCreationHandler) {itemCreationHandler();}
            setActivated(false);
        })
        setTitle("");
        setFullTitle("");
        setDescription("");
        setActive("");
        setProbability("");
    }

    return(

        <div className="itemCreateMain">

        {!activated && 
        <div className="itemCreateActivationContainer">
            <div className="itemCreateActivation" onClick = {()=>setActivated(true)}>
                +
            </div>
        </div>
        }

        {activated && 
            <div className="">
                Activated
                


                <Modal size="lg" show = {activated} backdrop="static" onHide={()=>setActivated(false)}>
                <Modal.Header closeButton>
                    Creeaza un premiu nou 
                </Modal.Header>
                <Modal.Body>
                    <div className="itemCreateModal">
                        <div>Titlu:</div>
                        <input type="text" value={title} onInput={(event)=>setTitle(event.target.value)}/>
                        <div>Titlu complet:</div>
                        <input type="text" value={fullTitle} onInput={(event)=>setFullTitle(event.target.value)}/>
                        <div>Descriere:</div>
                        <textarea type="text" value={description} onInput={(event)=>setDescription(event.target.value)}/>
                        <div>Probabilitate de castig:</div>
                        <input type="number" min="0" max="100" value={probability} onInput={(event)=>setProbability(event.target.value)}/>
                        <div>Premiu activ:</div>
                        <input type="checkbox"  checked={active} onInput={(event)=>setActive((active?false:true))}/>

                        <div className="createItemSubmitButtonContainer"> 
                            <button className="createItemSubmitButton" onClick={()=>createNewPrize()}>Adauga</button>
                        </div>
                    </div>

                </Modal.Body>

            </Modal>
            </div>
        }
        </div>
    );

}