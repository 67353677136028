import React, { useEffect, useRef, useState } from "react" 
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router";
import "./lucky.css";

export default function LuckyWheel({ zones, prizes , onPrizeWon}) {
  const [rotation, setRotation] = useState(0);
  const [isSpinning, setIsSpinning] = useState(false);

  const [animationTime,setAnimationTime] = useState(1);

  const userRotated = useRef(false);

  const redirect = useNavigate();

  let [lastWon, setLastWon] = useState(null);
  let [lastSubWon, setLastSubWon] = useState(null);

  useEffect(()=>{
    if(!isSpinning && userRotated.current)
    {

      let anglePZ = 360 /zones;
      let fra = (90-anglePZ) /2;
      let starAgle = (anglePZ+fra);
     
      let nr = (rotation)%360;
      let adjAngle  = nr-fra;
      if(adjAngle<0)
      {
        adjAngle = 360+adjAngle;
      }

      let ind = Math.floor(adjAngle/anglePZ);
    
      onPrizeWon(prizes[ind], (text)=>{
        setLastWon(text[0]);
        setLastSubWon(text[1]);
       });
      

      //alert ("Won"+ JSON.stringify(prizes[ind]));

    }
  },[isSpinning]);
  const spinWheel = () => {
    console.log("R", isSpinning);
    if (isSpinning) return; // Prevent re-spinning while already spinning
    setIsSpinning(true);
    setLastWon(null);
    setLastSubWon(null);

    const randomMultiplier = Math.floor(Math.random() * 10) + 5; // Random spins between 3 and 8
    const randomOffset = Math.floor(Math.random() * 360); // Random degrees
    const totalRotation = rotation + randomMultiplier * 360 + randomOffset;

    setAnimationTime(randomMultiplier);
    setRotation(totalRotation);

    userRotated.current = true;
    // Allow re-spin after animation ends
    setTimeout(() =>{setAnimationTime(0); setRotation((r)=>r%360);  setIsSpinning(false);}, randomMultiplier*1000);
  };

  const getSegmentStyles = () => {
    const segmentAngle = 360 / zones; // Angle for each segment
    return prizes.map((prize, index) => ({
      transform: `rotate(${-segmentAngle * index}deg)`,
      background: index % 3 === 0 ? "#D72638" : (index%3===1?"#007F5F":"#FFFFFF"),
     
    }));
  };
  const getSegmentSpamStyles = () => {
    const segmentAngle = 360 / zones; // Angle for each segment
    return prizes.map((prize, index) => ({
       color: index % 3 === 2 ? "black" : "white"
     
    }));
  };

  let computeProcent = ()=>{
    let zonesInternal = Math.max(4, zones);
    let a1 = 1.0 * 360/zonesInternal;
    let a2 = (90-a1)/2.0  +zones/1.9;//(Math.cos(Math.PI * a1/180)*5.5);
    let a22 = a2/45 *90;
    let a222 = Math.sin(Math.PI * a2/180) *100;
    //let a3 = a2/45 * 100;
    //console.log("C",a1,a2,a22, a222)
    return 100 - a222;
  }
  let computeFontSize = ()=>{
       //((computeProcent())*2.5)+ "%";

       return "1.5rem";
  }
  return (

    <div className="wheel-main">
    <div className="background-container-removed"></div>
    <div className="actions">
      <div className="walletButtonContainer" onClick={()=>redirect("/wallet")}> 
        <div className="walletIcon_removed"></div>
        <button  className="prizesButton">Portofelul meu</button>
      </div>



      <Modal className="wheelPrizeModal" size="lg"  centered show = {lastWon} backdrop="true" onHide={()=>setLastWon(null)}>
                <Modal.Header className="wheelPrizeModal"  closeButton>
                Ati castigat:
                </Modal.Header>
                <Modal.Body >
                <div className="wonTextWrapper">
                  <div className="wonTextContainer">
                      <div className="wonText">{lastWon?lastWon:""}</div>
                  </div>
                <div className="subWonText">{lastSubWon?lastSubWon:""}</div>

                </div>

                </Modal.Body>

            </Modal>


    </div>
    <div className="lucky-wheel-container">
    
      <div className="indicator"></div>
      <div className="wheel" style={{ transform: `rotate(${rotation}deg)` ,
        transition: `transform ${animationTime}s cubic-bezier(0.33, 1, 0.68, 1)`}}>
        {getSegmentStyles().map((style, index) => (
          <div
            key={index}
            className="segment"
            style={{
              ...style,
              clipPath: `polygon(100% 100%,  ${
                 computeProcent()
              }% 0%,
              0% ${
                computeProcent()
              }%, 100% 100%)`,
               
            }}
          >
            <div className="span" style={{color: (index%3===2?"#007F5F":"white")}}><div>{prizes[index].title}</div></div>
          </div>
        ))}

      <div className = "spin" onClick={spinWheel} disabled={isSpinning} >
        &#x21bb;  
 
      </div>
      </div>
 
     
    </div>
    </div>
  );
}
