import "./home.css"
import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router";
import { performHttpRequest } from "../api/http/generic";
import PATH from "../configuration/api/http/path";




export default function OpenInBrowser({authenticatedUser, fetchUser}){


    const redirect = useNavigate();
 
    return(<div className="homeMain">
        <div className="buttonListWrapper">
           

        <div className="bW"><button onClick={()=>{window.open('https://maxi-turda.com', '_system');}}>Deschide in browser</button></div>

        </div>
        </div>);
}