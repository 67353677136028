import "./luckyPage.css";
import React, { useEffect, useState } from "react";
import LuckyWheel from "./lucky";
import { performHttpRequest } from "../api/http/generic";
import PATH from "../configuration/api/http/path";
 

export default function LuckyPage(){

    let [prizes, setPrizes] = useState([]);


 
    useEffect(()=>{

        fetchWheel();
    },[]);
    let fetchWheel = ()=>{
        performHttpRequest("GET", PATH.getWheel, null, null, (r,c,e,j)=>{
            if(c>=200 && c<=206 && j)
            {
                processFetchedWheel(r);
            }
        })
    }
    let processFetchedWheel = (wheel)=>{
        console.log("wheel", wheel);

        let result = [];
        let end = false;
        while(!end){
            end = true;
            for(let index in wheel)
            {
            
                let prize = wheel[index];
            
                if( prize.probability>0)
                {
                    result.push(prize);
                    prize.probability --;
                    end=false;

                }

            }
        }
        setPrizes(result);
    }




    let   addPrize= async(p, c)=>{

          let promise = new Promise ((reso,rj)=>{
        performHttpRequest("POST", PATH.addPrize, null, JSON.stringify(p), (r,c,e,j)=>{
            if(c>=200 && c<=206 & j)
            {
                if(r.available>=0)
                {
                    reso([""+(p.fullTitle||p.title), "Am adaugat premiul in portofelul dumneavoastra. Mai aveti "+r.available+ (r.available===1?" pozitie libera":" pozitii libere")+" in portofel."]);
                }
                else{
                    reso( [""+(p.fullTitle||p.title), "Nu mai aveti pozitii libere in portofel pentru a putea salva premiul."]);

                }
            }
            reso( [""+(p.fullTitle||p.title), "Ceva nu a functionat corect, verificati portofelul pentru premiile salvate si numarul de pozitii libere."]);
        });
    });
    c([""+(p.fullTitle||p.title), "Se incearca adaugarea premiului in portofelul dumneavoastra..."]);
    c(await promise);

 
}
    


    return (
        <LuckyWheel   onPrizeWon={async (p,c)=>{ addPrize(p,c);}} zones={prizes.length} prizes={prizes}/>
    );
}